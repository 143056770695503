import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Accordion from "../components/accordian";
import External from "../components/external";
import Founders from '../components/founders'

const IntroSection = styled.div`
  background:#3A3A39;
  color:white;
  margin-bottom:3em;

`

const MoreInfo = styled.div`
    padding: 3em;

    @media screen and (max-width: 900px) {
      padding: 0;
  }

    
    p {
      margin-bottom:10px;  
    }

    a {
      cursor: pointer;
    }
`

const IntroContent = styled.div`
  padding:1em 2em;
  max-width:1120px;
  margin:0 auto;


  h1 {
    margin:1em 0;
  }
  p {
    max-width:600px;
    margin-bottom:2em;
    font-size:1.2em;
  }
`


const Company = styled.div`
  padding:0 2em;
  max-width:1120px;
  margin:1em auto;

  .accordion-wrapper {
    margin:1em 0;
  }

  img {
    max-width:140px;
  }

  .accordion {
  width: 100%;
  overflow: hidden;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    cursor: pointer;
    user-select: none;

    .accordion-header-title {
      display: flex;
      align-items: center;
    }
    
    .accordion-header-icon {
      transition: transform 0.35s ease-in-out;
    }
  }

  &-content {
    overflow:hidden;
    transition:all 0.3s ease-in-out;
    height:auto;
  }
}
.accordion-collapsed {
  .accordion-header {
    
    .accordion-header-icon {
      transform: rotate(0deg);
    }
  }
  .accordion-content {
    padding: 0 ; 
  } 
}


.accordion-expanded {
  .accordion-header {
   
    
    .accordion-header-icon {
      transform: rotate(90deg);
    }
  }
  
  .accordion-content {
    padding: 10px 0; 

    @media screen and (max-width: 850px) {
      min-height:500px;
    }

  } 
}

  h1 {
    margin:1em 0;
  }
  
  div {
    padding:10px 0;
    display:grid;
    
  }

  @media screen and (max-width: 850px) {
      grid-template-columns: repeat(1,1fr);
    }
`

const CompanyListing = styled.div`
    display:grid;
    grid-template-columns:auto auto;
    grid-gap:8em;

    p {
      max-width:450px;
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(1,1fr);
      grid-gap:1em;
  }

    a {
      color:black;
    }
`



class Portfolio extends React.Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     selectedIndex: 0
  //   };
  // }

  constructor(props) {
    super(props)
    this.transitionEnd = this.transitionEnd.bind(this)
    this.mountStyle = this.mountStyle.bind(this)
    this.unMountStyle = this.unMountStyle.bind(this)
    this.state ={ //base css
      show: true,
      selectedIndex: 0,
      style :{
        opacity: 0,
        transition: 'all .4s ease',
      }
    }
  }
  
  componentWillReceiveProps(newProps) { // check for the mounted props
    if(!newProps.mounted)
      return this.unMountStyle() // call outro animation when mounted prop is false
    this.setState({ // remount the node when the mounted prop is true
      show: true
    })
    setTimeout(this.mountStyle, 10) // call the into animation
  }
  
  unMountStyle() { // css for unmount animation
    this.setState({
      style: {
        opacity: 0,
        transition: 'all 1s ease',
      }
    })
  }
  
  mountStyle() { // css for mount animation
    this.setState({
      style: {
        opacity: 1,
        transition: 'all 1s ease',
      }
    })
  }
  
  componentDidMount(){
    setTimeout(this.mountStyle, 10) // call the into animation
  }
  
  transitionEnd(){
    if(!this.props.mounted){ // remove the node on transition end when the mounted prop is false
      this.setState({
        show: false
      })
    }
  }

  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} >
        <SEO title="Portfolio" />
        <div style={this.state.style} onTransitionEnd={this.transitionEnd}>
        <IntroSection >
          <IntroContent>
            <h1> Portfolio Companies</h1>
            <p>We invest in, develop, and operate companies innovating at the intersection of real estate, health, and technology. We typically like to get involved as early as possible. </p>
          </IntroContent>
        </IntroSection>
        <Company >
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
        <Accordion
            label={title}
            collapsed={true}
            accordionIcon={
            <svg
              className="accordion-label-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 256 512"
            >
              <path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z" />
           </svg>
          }
         >
           <CompanyListing>
            <div>
            <div dangerouslySetInnerHTML={{
                __html: node.html,
                }}
              />

            </div>
            <div>
              <img src={node.frontmatter.logo} />
              <a href={node.frontmatter.website} target="_blank">Website <External /></a>
            </div>
           </CompanyListing>
           

          
        </Accordion>
          )
       })}
   
        </Company>
        </div>
      </Layout>
    )
  }
}

export default Portfolio

export const pageQuery = graphql`
  query {
 
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
      ) {
      edges {
        node {
            html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            logo
            website
            founders {
              name
            }
          }
        }
      }
    }
  }
`
