import React from 'react'
import styled from "styled-components"

const CompanyItem = styled.div`
  border-bottom:1px solid;
  cursor:pointer;
`

class Accordian extends React.Component {
        state = {
          isCollapsed: true
        };
      
        accordionRef = React.createRef();
        accordionContentHeight = 0;
      
        componentDidMount() {
          // get the height of accordion content
          this.accordionContentHeight = this.accordionRef.current.clientHeight;
      
          // use promise to make sure that first we set the state property "isCollapsed" then we run toggleAccordion method
          const setCollapseValue = new Promise(resolve => {
            // set isCollapsed value
            resolve(
              this.setState({
                isCollapsed:
                  typeof this.props.collapsed !== "undefined"
                    ? this.props.collapsed
                    : true
              })
            );
          });
      
          setCollapseValue.then(() => {
            this.toggleAccordion();
          });
        }
      
        toggleAccordion = () => {
          const { isCollapsed } = this.state;
      
          if (isCollapsed) {
            // set the height of the accordion content to 0
            this.accordionRef.current.style.height = 0 + "px";
          } else {
            // set the height of the accordion content to it's actual height
            this.accordionRef.current.style.height =
               "300px";

          }
          this.setState(({ isCollapsed }) => {
            return {
              isCollapsed: !isCollapsed
            };
          });
        };
      
        render() {
          const { isCollapsed } = this.state;
          const { label, accordionIcon, children } = this.props;
      
          return (
            <div
              className={`accordion-wrapper ${
                isCollapsed ? "accordion-expanded" : "accordion-collapsed"
              }`}
            >
              <CompanyItem className="accordion">
                <div className="accordion-header" onClick={this.toggleAccordion}>
                  <h2 className="accordion-header-title">{label}</h2>
                  <span className="accordion-header-icon">
                    {accordionIcon ? (
                      accordionIcon
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 256 512"
                      >
                        <path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z" />
                      </svg>
                    )}
                  </span>
                </div>
                <div className="accordion-content" ref={this.accordionRef}>
                  {children}
                </div>
              </CompanyItem>
            </div>
          );
        }
      }

export default Accordian