import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components"

const Founder = styled.div`
    margin:2em 0;

`



const Founders = ({ founders }) => (
  
  <Founder>
    {founders.map(founders => (
           <div>
            <h3>{founders.name}</h3>
           </div>
    ))}

  </Founder>
)

Founders.propTypes = {
    founders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
}

export default Founders